@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&family=Work+Sans:ital,wght@0,100..900;1,100..900&display=swap');

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  :root {
    --defaultWhiteColor: #ffffff;
    --defaultPurple: #6825F7;
    --defaultPink: #C325FF;
    --defaultGrey: #F7F7F7;
    --GreyLight: #D9D9D91A;
    --defaultBlack: #000;
    --themeColor: #F5F5F5;
    --BlueColor: #2579F7;
    --OrangeColor: #F99C47;
    --SkyBlue: #007AFF
  }
  *,body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
    scroll-behavior: smooth;
  }
  h1 {
    @apply text-title36;
  }

  h2 {
    @apply text-title24;
  }

  h3 {
    @apply text-title20;
  }

  h4 {
    @apply text-description;
  }
}
@layer components {
  .custom-container {
    @apply w-95 m-auto;
  }

  .header {
    @apply bg-White shadow-customShadow h-[100px] sticky top-0 z-50;
  }
}